import React, { useState } from "react";
import CourseCard from "./CourseCard";
import ReactPaginate from "react-paginate";

function CourseList({ courses }) {
  const itemsPerPage = 15; // Number of courses to display per page
  const [currentPage, setCurrentPage] = useState(0); // Page number is 0-based
  const [expandedCourse, setExpandedCourse] = useState(null);

  // Calculate the start and end indices for the current page
  const startIndex = currentPage * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  // Get the courses to display on the current page
  const coursesToDisplay = courses.slice(startIndex, endIndex);

  // Handle pagination when a page number is clicked
  const handlePageClick = (selectedPage) => {
    setCurrentPage(selectedPage.selected);
    setExpandedCourse(null);
  };

  const toggleExpand = (course) => {
    setExpandedCourse(expandedCourse === course ? null : course);
  };

  return (
    <div className="course-list">
      {/* Row showing the number of courses being displayed */}
      <div className="course-count">
        Showing {startIndex + 1} -{" "}
        {endIndex > courses.length ? courses.length : endIndex} of{" "}
        {courses.length} courses
      </div>

      {coursesToDisplay.map((course) => (
        <CourseCard
          key={course.id}
          isExpanded={course === expandedCourse}
          course={course}
          onToggleExpand={toggleExpand}
        />
      ))}

      {/* Pagination */}
      <ReactPaginate
        previousLabel={"Previous"}
        nextLabel={"Next"}
        breakLabel={"..."}
        breakClassName={"break-me"}
        pageCount={Math.ceil(courses.length / itemsPerPage)}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        onPageChange={handlePageClick}
        containerClassName={"pagination"}
        subContainerClassName={"pages pagination"}
        activeClassName={"active"}
      />
    </div>
  );
}

export default CourseList;
