// Login.js
import React, { useState } from "react";
import "./Login.css"; // Import the CSS file
import CourseFinder from "./CourseFinder"; // Import the CourseFinder component

const usersList = [
  { email: "nasreen.f@envisionoverseas.in", password: "Nasreen@123" },
  { email:"sahiba.s@envisionoverseas.in", password:"Sahiba@21202"},
  { email: "mahi@envisionoverseas.in", password: "Mahi20188" },
  { email: "shobana@envisionoverseas.in", password: "Shobana20864" },
  { email: "saksham@envisionoverseas.in", password: "Saksham20526" },
  { email: "maria@envisionoverseas.in", password: "Maria@19850" },
  { email: "sara@envisionoverseas.in", password: "Sara@18498" },
  { email: "devam@envisionoverseas.in", password: "Devam@19512" },
  { email: "priya.s@envisionoverseas.in", password: "Priya@18836" },
  { email: "sahil@envisionoverseas.in", password: "Sahil@19174" },
  { email: "akshada@envisionoverseas.in", password: "Akshada@16808" },
  { email: "shubhpreet@envisionoverseas.in", password: "Shubhpreet@17176" },
  { email: "fiona@envisionoverseas.in", password: "Fiona@18160" },
  { email: "ashwini.s@envisionoverseas.in", password: "Ashwini@17822" },
  { email: "himangini@envisionoverseas.in", password: "Himangini@17484" },
  { email: "shweta.m@envisionoverseas.in", password: "Shweta@456" },
  { email: "rupali@envisionoverseas.in", password: "Rupali@789" },
  { email: "harshali@envisionoverseas.in", password: "Harshali@1122" },
  { email: "ronak@envisionoverseas.in", password: "Ronak@1455" },
  { email: "azmina@envisionoverseas.in", password: "Azmina@1788" },
  { email: "rohitkumar@envisionoverseas.in", password: "Rohitkumar@2121" },
  { email: "aisha@envisionoverseas.in", password: "Aisha@2454" },
  { email: "alisha@envisionoverseas.in", password: "Alisha@2787" },
  { email: "divya.p@envisionoverseas.in", password: "Divya@3120" },
  { email: "meghna@envisionoverseas.in", password: "Meghna@3453" },
  { email: "kavita@envisionoverseas.in", password: "Kavita@3786" },
  { email: "sadaf@envisionoverseas.in", password: "Sadaf@4119" },
  { email: "gitika@envisionoverseas.in", password: "Gitika@4452" },
  { email: "karishma.s@envisionoverseas.in", password: "Karishma@4785" },
  { email: "shalika@envisionoverseas.in", password: "Shalika@5118" },
  { email: "akanksha.m@envisionoverseas.in", password: "Akanksha@5451" },
  { email: "rajeshwari@envisionoverseas.in", password: "Rajeshwari@5784" },
  { email: "veena@envisionoverseas.in", password: "Veena@6117" },
  { email: "velu@envisionoverseas.in", password: "Velu@6450" },
  { email: "crisselle@envisionoverseas.in", password: "Crisselle@6783" },
  { email: "navyashree@envisionoverseas.in", password: "Navyashree@7116" },
  { email: "aarthi@envisionoverseas.in", password: "Aarthi@7449" },
  { email: "garima.s@envisionoverseas.in", password: "Garima@7782" },
  { email: "seema.t@envisionoverseas.in", password: "Seema@8115" },
  { email: "meenakshi@envisionoverseas.in", password: "Meenakshi@8448" },
  { email: "archi@envisionoverseas.in", password: "Archi@8781" },
  { email: "archika@envisionoverseas.in", password: "Archika@9114" },
  { email: "roshni@envisionoverseas.in", password: "Roshni@9447" },
  { email: "ratna@envisionoverseas.in", password: "Ratna@9780" },
  { email: "shruti.r@envisionoverseas.in", password: "Shruti@10113" },
  { email: "sahiba@envisionoverseas.in", password: "Sahiba@10446" },
  { email: "priyanka.d@envisionoverseas.in", password: "Priyanka@10779" },
  { email: "kushagra@envisionoverseas.in", password: "Kushagra@11112" },
  { email: "arminder@envisionoverseas.in", password: "Arminder@11445" },
  { email: "kahkasha@envisionoverseas.in", password: "Kahkasha@11778" },
  { email: "somdutta@envisionoverseas.in", password: "Somdutta@12111" },
  { email: "anmol@envisionoverseas.in", password: "Anmol@12444" },
  { email: "khushboo@envisionoverseas.in", password: "Khushboo@12777" },
  { email: "monika@envisionoverseas.in", password: "Monika@13110" },
  { email: "payal.g@envisionoverseas.in", password: "Payal@13443" },
  { email: "shubhnitta@envisionoverseas.in", password: "Shubhnitta@13776" },
  { email: "radhika@envisionoverseas.in", password: "Radhika@14109" },
  { email: "vaishnavi@envisionoverseas.in", password: "Vaishnavi@14442" },
  { email: "kannan@envisionoverseas.in", password: "Kannan@14781" },
  { email: "pushpanjali@envisionoverseas.in", password: "Pushpanjali@15119" },
  { email: "namarta@envisionoverseas.in", password: "namarta15456" },
  { email: "beenal@envisionoverseas.in", password: "Beenal@15794" },
  { email: "jagruti@envisionoverseas.in", password: "Jagruti@16132" },
  { email: "virender@envisionoverseas.in", password: "Virender@16470" }
];

const Login = ({ setIsAuthenticated }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [authenticated, setAuthenticated] = useState(
    localStorage.getItem("isAuthenticated") === "true" // Check localStorage on component mount
  );

  const handleLogin = () => {
    // Check if the email and password match any entry in the usersList
    const isValidCredentials = usersList.some(
      (user) => user.email === email && user.password === password
    );

    if (isValidCredentials) {
      setIsAuthenticated(true);
      setAuthenticated(true);
      localStorage.setItem("isAuthenticated", "true"); // Store in localStorage
    } else {
      alert("Invalid email or password");
    }
  };

  return (
    <div className="box">
      <span className="borderLine"></span>
      {authenticated ? (
        <CourseFinder />
      ) : (
        <form onSubmit={(e) => e.preventDefault()}>
          <h2>Sign In</h2>
          <div className="inputBox">
            <input
              type="text"
              placeholder="Email"
              required="required"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              autoComplete="username"
            />
          </div>
          <div className="inputBox">
            <input
              type="password"
              placeholder="Password"
              required="required"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              autoComplete="current-password"
            />
            <i></i>
          </div>

          <input type="submit" value="Login" onClick={handleLogin} />
        </form>
      )}
    </div>
  );
};

export default Login;
