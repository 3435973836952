// App.js
import React, { useState } from "react";
import Header from "./components/Header";
import CourseFinder from "./components/CourseFinder";
import Login from "./components/Login";

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(
    localStorage.getItem("isAuthenticated") === "true" // Check localStorage on component mount
  );

  return (
    <div className="App">
      <main>
        {isAuthenticated ? (
          <>
            <Header />
            <CourseFinder />
          </>
        ) : (
          <Login setIsAuthenticated={setIsAuthenticated} />
        )}
      </main>
    </div>
  );
}

export default App;
