import React, { useState, useEffect } from "react";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { debounce } from "lodash";

const Filters = ({ courses, setFilteredCourses }) => {
  const [uniqueLocations, setUniqueLocations] = useState([]);
  const [uniqueUniversities, setUniqueUniversities] = useState([]);
  const [uniqueQualifications, setUniqueQualifications] = useState([]);
  const [uniqueNextIntakes, setUniqueNextIntakes] = useState([]);
  const [uniqueEntryScores, setUniqueEntryScores] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("");
  const [debouncedSearch, setDebouncedSearch] = useState("");

  const [selectedFilters, setSelectedFilters] = useState({
    Location: "",
    University: "",
    Qualification: "",
    NextIntake: "",
    EntryScore: "",
  });

  const [universitySearch, setUniversitySearch] = useState("");
  const [selectedUniversity, setSelectedUniversity] = useState(null);

  const [isLoading, setIsLoading] = useState(false);

  const handleApplyFilters = () => {
    setIsLoading(true); // Set loading state to true when the button is pressed
    applyFilters(); // Apply filters as usual

    // Simulate a 2-second loading delay
    setTimeout(() => {
      setIsLoading(false); // Set loading state back to false after 2 seconds
    }, 300);
  };
  // Define an array of months in the desired order
  const monthOrder = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  useEffect(() => {
    const handleSearchDebounced = debounce(async () => {
      await applyFilters();
    }, 500);

    handleSearchDebounced();

    return handleSearchDebounced.cancel;
  }, [debouncedSearch, selectedCountry, selectedFilters]);

  // Add this useEffect to update debouncedSearch when searchText changes
  // useEffect(() => {
  //   setDebouncedSearch(searchText);
  // }, [searchText]);

  useEffect(() => {
    // Extract unique values
    const locations = [
      ...new Set(courses.map((course) => course.Location)),
    ].filter(Boolean);
    const universities = [
      ...new Set(courses.map((course) => course["University Name"])),
    ].filter(Boolean);
    const qualifications = [
      ...new Set(courses.map((course) => course.Qualification)),
    ].filter(Boolean);
    const nextIntakes = [
      ...new Set(courses.map((course) => course["Next intake"])),
    ].filter(Boolean);
    const entryScores = [
      ...new Set(courses.map((course) => course["Entry score"])),
    ].filter(Boolean);

    setUniqueLocations(locations);
    setUniqueUniversities(universities);
    setUniqueQualifications(qualifications);
    setUniqueNextIntakes(nextIntakes);
    setUniqueEntryScores(entryScores);
  }, [courses]);

  const getMonthIndex = (dateString) => {
    return monthOrder.indexOf(dateString);
  };

  // Sort the uniqueNextIntakes based on the month index
  uniqueNextIntakes.sort((a, b) => {
    const monthA = a.split(" ")[0]; // Extract the month from the date string
    const monthB = b.split(" ")[0];
    return getMonthIndex(monthA) - getMonthIndex(monthB);
  });

  const handleFilterChange = (filterKey, value) => {
    // Update selected filters
    setSelectedFilters({
      ...selectedFilters,
      [filterKey]: value,
    });
  };

  const applyFilters = () => {
    // Apply filters and update the filtered courses
    const filteredCourses = courses.filter((course) => {
      const courseName = (course["Course Name"] || "").toLowerCase();
      const university = (course["University Name"] || "").toLowerCase();

      const search = debouncedSearch.toLowerCase();

      return (
        (courseName.includes(search) || university.includes(search)) &&
        (!selectedFilters.Location ||
          course.Location === selectedFilters.Location) &&
        (!selectedFilters.University ||
          course["University Name"] === selectedFilters.University) &&
        (!selectedFilters.Qualification ||
          course.Qualification === selectedFilters.Qualification) &&
        (!selectedFilters.NextIntake ||
          course["Next intake"] === selectedFilters.NextIntake) &&
        (!selectedFilters.EntryScore ||
          course["Entry score"] === selectedFilters.EntryScore)
      );
    });

    setFilteredCourses(filteredCourses);
  };

  const resetFilters = () => {
    // Reset selected filters and show all courses
    setSelectedFilters({
      Location: "",
      University: "",
      Qualification: "",
      NextIntake: "",
      EntryScore: "",
    });
    setSearchText("");
    setSelectedUniversity(null);

    // Reset the dropdowns to their default values
    document.getElementById("location-filter").value = "";
    document.getElementById("university-filter").value = "";
    document.getElementById("qualification-filter").value = "";
    document.getElementById("nextintake-filter").value = "";
    document.getElementById("entryscore-filter").value = "";

    setFilteredCourses(courses);
  };

  // Filter universities based on location and search text, sorted alphabetically
  const universitiesByLocation = uniqueUniversities
    .filter((university) =>
      courses.some(
        (course) =>
          course.Location === selectedFilters.Location &&
          course["University Name"] === university
      )
    )
    .filter((university) =>
      university.toLowerCase().includes(universitySearch.toLowerCase())
    )
    .sort();

  return (
    <div className="filters">
      <div className="search-container">
        <input
          type="text"
          placeholder={searchText || "Search..."}
          className="search-input"
          value={searchText}
          onChange={(e) => {
            setSearchText(e.target.value);
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              setDebouncedSearch(e.target.value);
              applyFilters();
            }
          }}
        />
        <FontAwesomeIcon
          className="search-btn"
          icon={faSearch}
          onClick={applyFilters}
        />
      </div>
      <div className="filters-box">
        <div className="filters-row">
          <label>
            <select
              id="location-filter"
              onChange={(e) => {
                handleFilterChange("Location", e.target.value);
                setSelectedCountry(e.target.value);
              }}
            >
              <option value="">All Countries</option>
              {uniqueLocations.sort().map((location) => (
                <option key={location} value={location}>
                  {location}
                </option>
              ))}
            </select>
          </label>

          <label>
            <Select
              classNamePrefix="mySelect"
              id="university-filter"
              options={uniqueUniversities
                .sort()
                .filter((university) =>
                  selectedCountry
                    ? courses.some(
                        (course) =>
                          course.Location === selectedCountry &&
                          course["University Name"] === university
                      )
                    : true
                )
                .sort()
                .map((university) => ({
                  label: university,
                  value: university,
                }))}
              onChange={(selectedOption) => {
                setSelectedUniversity(selectedOption);
                handleFilterChange(
                  "University",
                  selectedOption ? selectedOption.value : ""
                );
              }}
              value={selectedUniversity}
              isSearchable
              placeholder="All Universities"
            />
          </label>
        </div>
      </div>
      <div className="filters-box">
        <div className="filters-row">
          <label>
            <select
              id="qualification-filter"
              onChange={(e) =>
                handleFilterChange("Qualification", e.target.value)
              }
            >
              <option value="">All Qualifications</option>
              {uniqueQualifications.sort().map((qualification) => (
                <option key={qualification} value={qualification}>
                  {qualification}
                </option>
              ))}
            </select>
          </label>
          <label>
            <select
              id="nextintake-filter"
              onChange={(e) => handleFilterChange("NextIntake", e.target.value)}
            >
              <option value="">All Intakes</option>
              {uniqueNextIntakes.map((intake) => (
                <option key={intake} value={intake}>
                  {intake}
                </option>
              ))}
            </select>
          </label>

          <label>
            <select
              id="entryscore-filter"
              onChange={(e) => handleFilterChange("EntryScore", e.target.value)}
            >
              <option value="">All Entry Scores</option>
              {uniqueEntryScores.sort().map((entryScores) => (
                <option key={entryScores} value={entryScores}>
                  {entryScores}
                </option>
              ))}
            </select>
          </label>
        </div>
      </div>
      <div className="buttonblock">
        <button onClick={handleApplyFilters} disabled={isLoading}>
          {isLoading ? (
            <div className="loader" /> // Show the loader when isLoading is true
          ) : (
            "Apply Filters"
          )}
        </button>
        <button onClick={resetFilters}>Reset Filters</button>
        {/* <button onClick={resetFilters}>Download Data</button> */}
      </div>
    </div>
  );
};

export default Filters;
