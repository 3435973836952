import React, { useState, useEffect } from "react";
import ZoomIcon from "../images/zoom.png";
import Filters from "./Filters";
import CourseList from "./CourseList";
import coursesData from "../data/courses.json";
import "./CourseFinder.css";

function CourseFinder() {
  const [courses, setCourses] = useState(coursesData);
  const [filteredCourses, setFilteredCourses] = useState(coursesData);

  const receiveFilteredCourses = (filteredCourses) => {
    setFilteredCourses(filteredCourses);
  };

  const scrollToTop = () => {
    document.documentElement.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    setCourses(coursesData);
    // Pass the initial list of courses as the default value for filteredCourses
    setFilteredCourses(coursesData);
  }, []);

  return (
    <div>
      <Filters courses={courses} setFilteredCourses={receiveFilteredCourses} />
      {/* Pass filteredCourses as the prop to CourseList */}
      <CourseList courses={filteredCourses} />
      <div className="zoom-icon">
        <div className="scroll-to-top-button" onClick={scrollToTop}>
          <img src={ZoomIcon} alt="Scroll to Top" />
        </div>
      </div>
    </div>
  );
}

export default CourseFinder;
