import React, { useState } from "react";
import "./CourseCard.css";
import Australia from "../images/country/australia.png";
import USA from "../images/country/usa.png";
import UK from "../images/country/uk.png";
import NewZ from "../images/country/newzealand.png";
import Ireland from "../images/country/ireland.png";
import Canada from "../images/country/canada.png";
import Singapore from "../images/country/singapore.png";
import Netherlands from "../images/country/netherlands.png";
import Italy from "../images/country/italy.png";
import Switzerland from "../images/country/switzerland.png";
import Dubai from "../images/country/dubai.png";
import France from "../images/country/france.png";
import Cyprus from "../images/country/cyprus.png";
import Germany from "../images/country/germany.png";

const CourseCard = ({ course, isExpanded, onToggleExpand }) => {
  let locationImage;

  if (course["Location"] === "Australia") {
    locationImage = Australia;
  } else if (
    course["Location"] === "United Kingdom" ||
    course["Location"] === "UK"
  ) {
    locationImage = UK;
  } else if (
    course["Location"] === "United States" ||
    course["Location"] === "USA"
  ) {
    locationImage = USA;
  } else if (course["Location"] === "New Zealand") {
    locationImage = NewZ;
  } else if (course["Location"] === "Canada") {
    locationImage = Canada;
  } else if (course["Location"] === "Ireland") {
    locationImage = Ireland;
  } else if (course["Location"] === "France") {
    locationImage = France;
  } else if (course["Location"] === "Singapore") {
    locationImage = Singapore;
  } else if (course["Location"] === "Australia") {
    locationImage = Australia;
  } else if (course["Location"] === "Cyprus") {
    locationImage = Cyprus;
  } else if (course["Location"] === "Netherlands") {
    locationImage = Netherlands;
  } else if (course["Location"] === "Germany") {
    locationImage = Germany;
  } else if (course["Location"] === "Italy") {
    locationImage = Italy;
  } else if (course["Location"] === "Switzerland") {
    locationImage = Switzerland;
  } else if (course["Location"] === "Dubai") {
    locationImage = Dubai;
  }

  const [showDetails, setShowDetails] = useState(false);

  const toggleDetails = () => {
    setShowDetails(!showDetails);
  };

  return (
    <div className="course-card">
      <div className="course-details">
        <h1>{course["Course Name"]}</h1>
        <p>
          <b>University:</b>
          {course["University Name"]}, {course["Location"]}
        </p>

        <p>
          <b>Qualification:</b>
          {course["Qualification"]}
        </p>

        <p>
          <b>Entry Score :</b>
          {course["Entry score"]}
        </p>
        <p>
          <b>Next Intake:</b>
          {course["Next intake"]}
        </p>
        <p>
          <b>Duration:</b>
          {course["Duration"]}
        </p>
        <p>
          <b>Fees:</b>
          {course["Fees"]}
        </p>

        <button
          className="connect-button"
          onClick={() => onToggleExpand(course)}
        >
          {isExpanded ? "View less" : "View more"}
        </button>

        {isExpanded && (
          <div className="course-details-section">
            <p style={{ fontSize: "16px" }}>
              <b>Entry Requirements :</b>
              {course["Entry Requirements"]}
            </p>
            <b>Other Intakes</b>
            {course["Intake and Location"] &&
              (course["Intake and Location"].startsWith("[") ? (
                // If it starts with '[', display in tabular format
                <table>
                  <tbody>
                    {course["Intake and Location"]
                      .slice(1, -1) // Remove the starting [ and ending ]
                      .split(", ")
                      .reduce((rows, item, index) => {
                        if (index % 3 === 0) {
                          rows.push([item]);
                        } else {
                          rows[rows.length - 1].push(item);
                        }
                        return rows;
                      }, [])
                      .map((row, rowIndex) => (
                        <tr key={rowIndex}>
                          {row.map((item, columnIndex) => (
                            <td key={columnIndex}>{item}</td>
                          ))}
                        </tr>
                      ))}
                  </tbody>
                </table>
              ) : (
                // If it doesn't start with '[', display as a paragraph
                <p>{course["Intake and Location"]}</p>
              ))}
          </div>
        )}
      </div>

      <div className="course-image">
        <img src={locationImage} alt={course["Location"]} />
      </div>
    </div>
  );
};

export default CourseCard;
