import React from "react";
import Logo from "../logo-white.jpeg";
// You can create a separate CSS file for styling

function Header() {
  return (
    <header className="navbar">
      <div className="navbar-left">
        <img
          src={Logo} // Replace with your logo URL
          alt="Company Logo"
          className="company-logo"
        />
      </div>
      <div className="navbar-center">
        <h1 className="page-title">Course Finder</h1>
      </div>
      <div className="navbar-right">
        <a
          href="https://api.whatsapp.com/send/?phone=9920706900&text&type=phone_number&app_absent=0"
          target="_blank"
          rel="noreferrer"
        >
          <button className="contact-button">Contact Us</button>
        </a>
      </div>
    </header>
  );
}

export default Header;
